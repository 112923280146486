import React, { useContext } from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Form, Button } from 'react-bootstrap';
import PortfolioContext from '../../context/context';
import Title from '../Title/Title';

const Contact = () => {
  const { contact } = useContext(PortfolioContext);
  const { cta } = contact;

  return (
    <section id="contact">
      <Container>
        <Title title="Contact" />
        <Fade bottom duration={800} delay={600} distance="30px">
          <div className="contact-wrapper">
            <p className="contact-wrapper__text">
              {cta || 'Would you like to work with me? Awesome!'}
            </p>
            <Form
              method="post"
              netlify-honeypot="bot-field"
              data-netlify="true"
              name="contact"
              className="form-items"
            >
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="contact" />
              <Form.Group className="mb-3 input-group-form" controlId="formName">
                <Form.Label className="label-contact">Your name:</Form.Label>
                <Form.Control size="lg" type="text" placeholder="Enter name" name="name" />
              </Form.Group>
              <Form.Group className="mb-3 input-group-form" controlId="formEmail">
                <Form.Label className="label-contact">Your email:</Form.Label>
                <Form.Control size="lg" type="email" placeholder="Enter email" name="email" />
              </Form.Group>
              <Form.Group className="mb-3 input-group-form" controlId="formMessage">
                <Form.Label className="label-contact">Message:</Form.Label>
                <Form.Control size="lg" as="textarea" rows={3} name="message" />
              </Form.Group>
              <Button type="submit" className="cta-btn cta-btn--resume">
                Send
              </Button>
            </Form>
          </div>
        </Fade>
      </Container>
    </section>
  );
};

export default Contact;
