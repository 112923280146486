import React, { useContext, useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-scroll';
import PortfolioContext from '../../context/context';
import MainGame from '../../game/main';

const Header = () => {
  const { hero } = useContext(PortfolioContext);
  const { title, name, subtitle, cta } = hero;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [oneGame, setOneGame] = useState(true);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);
  useEffect(() => {
    if (oneGame) {
      const element = document.getElementById('backgroundGame');
      // eslint-disable-next-line no-console
      console.log('Created Game');
      const game = new MainGame(element);
      element.appendChild(game.PixiApp.view);
      setOneGame(false);
    }
  }, []);

  return (
    <section id="hero" className="jumbotron">
      <div id="backgroundGame" />
      <Container>
        <Fade left={isDesktop} bottom={isMobile} duration={800} delay={250} distance="30px">
          <h1 className="hero-title">
            {title || 'Hello, my name is'}{' '}
            <span className="text-color-main">{name || 'João Augusto Lima'}</span>
            <br />
            {subtitle || 'I am an aspiring Game Developer.'}
          </h1>
        </Fade>
        <Fade left={isDesktop} bottom={isMobile} duration={800} delay={250} distance="30px">
          <p className="hero-cta">
            <span className="cta-btn cta-btn--hero">
              <Link to="about" smooth duration={800}>
                {cta || 'Know more'}
              </Link>
            </span>
          </p>
        </Fade>
      </Container>
    </section>
  );
};

export default Header;
