/* eslint-disable no-continue */
/* eslint-disable no-param-reassign */
import shipJson from './sprites/ship/Ship.json';
import shipURL from './sprites/ship/Ship.png';
import missileJson from './sprites/missile/Missile.json';
import missileURL from './sprites/missile/Missile.png';
import bigAsteJson from './sprites/bigAste/BigAsteroide.json';
import bigAsteURL from './sprites/bigAste/BigAsteroide.png';
import smallAsteJson from './sprites/smallAste/SmallAsteroid.json';
import smallAsteURL from './sprites/smallAste/SmallAsteroid.png';
import explosionJson from './sprites/explosion/ExplosionSpace.json';
import explosionURL from './sprites/explosion/ExplosionSpace.png';

function hitTestRectangle(r1, r2) {
  // Define the variables we'll need to calculate
  let hit = false;
  let combinedHalfWidths = 0;
  let combinedHalfHeights = 0;
  let vx = 0;
  let vy = 0;

  // Find the center points of each sprite
  r1.centerX = r1.x + r1.width / 2;
  r1.centerY = r1.y + r1.height / 2;
  r2.centerX = r2.x + r2.width / 2;
  r2.centerY = r2.y + r2.height / 2;

  // Find the half-widths and half-heights of each sprite
  r1.halfWidth = r1.width / 2;
  r1.halfHeight = r1.height / 2;
  r2.halfWidth = r2.width / 2;
  r2.halfHeight = r2.height / 2;

  // Calculate the distance vector between the sprites
  vx = r1.centerX - r2.centerX;
  vy = r1.centerY - r2.centerY;

  // Figure out the combined half-widths and half-heights
  combinedHalfWidths = r1.halfWidth + r2.halfWidth;
  combinedHalfHeights = r1.halfHeight + r2.halfHeight;

  // Check for a collision on the x axis
  if (Math.abs(vx) < combinedHalfWidths) {
    // A collision might be occurring. Check for a collision on the y axis
    if (Math.abs(vy) < combinedHalfHeights) {
      // There's definitely a collision happening
      hit = true;
    } else {
      // There's no collision on the y axis
      hit = false;
    }
  } else {
    // There's no collision on the x axis
    hit = false;
  }

  // `hit` will be either `true` or `false`
  return hit;
}
class MainGame {
  constructor(element) {
    // eslint-disable-next-line global-require
    const PIXI = require('pixi.js');
    this.PIXI = PIXI;
    this.app = new this.PIXI.Application({
      width: element.offsetWidth,
      height: element.offsetHeight,
      backgroundAlpha: 0,
    });
    this.i = 1;
    this.app.renderer.autoResize = true;
    this.sprittesLoading = true;
    this.shipSprites = null;
    this.missileSprites = null;
    this.bigAsteSprites = null;
    this.smallAsteSprites = null;
    this.supportOrientation = true;
    this.usingKeyboard = false;
    this.keyboardRotateDiretion = 0;
    this.keyboardShipForward = false;
    this.keyboardShipSpeedx = 0;
    this.keyboardShipSpeedy = 0;
    this.keyboardShipSpeedMax = 3;

    window.addEventListener('resize', () => {
      if (element != null) {
        this.app.renderer.resize(element.offsetWidth, element.offsetHeight);
      }
      if (!this.sprittesLoading) this.screenResize();
    });
    this.app.renderer.resize(element.offsetWidth, element.offsetHeight);
    this.app.renderer.plugins.interaction.autoPreventDefault = false;
    this.app.renderer.view.style.touchAction = 'auto';
    this.app.loader
      .add('ship', shipURL)
      .add('missile', missileURL)
      .add('bigAste', bigAsteURL)
      .add('smallAste', smallAsteURL)
      .add('explosion', explosionURL)
      .load(() => this.setup());
  }

  setup() {
    // Ship sprites
    const shipSpriteSheet = new this.PIXI.Spritesheet(
      this.app.loader.resources.ship.texture,
      shipJson
    );
    shipSpriteSheet.parse(() => {
      this.shipSprites = shipSpriteSheet.textures;
    });
    this.ship = new this.PIXI.Sprite(this.shipSprites['Ship0.png']);
    this.ship.anchor.set(0.5, 0.5);
    this.ship.texture.baseTexture.scaleMode = this.PIXI.SCALE_MODES.NEAREST;
    this.ship.scale.set(1.5, 1.5);

    this.ship.x = this.app.renderer.width / 2;
    this.ship.y = this.app.renderer.height / 2;

    // Backstage for click events
    this.backStage = new this.PIXI.Graphics();
    this.backStage.beginFill(0xffffff, 1);
    this.backStage.drawRect(0, 0, this.app.renderer.width, this.app.renderer.height);
    this.backStage.endFill();

    // Missiles sprites
    const missileSpriteSheet = new this.PIXI.Spritesheet(
      this.app.loader.resources.missile.texture,
      missileJson
    );
    missileSpriteSheet.parse(() => {
      this.missileSprites = missileSpriteSheet.textures;
    });
    this.missileContainer = new this.PIXI.Container();

    // Big Asteroids sprite
    const bigAsteSpriteSheet = new this.PIXI.Spritesheet(
      this.app.loader.resources.bigAste.texture,
      bigAsteJson
    );
    bigAsteSpriteSheet.parse(() => {
      this.bigAsteSprites = bigAsteSpriteSheet.textures;
    });
    // Small Asteroids sprite
    const smallAsteSpriteSheet = new this.PIXI.Spritesheet(
      this.app.loader.resources.smallAste.texture,
      smallAsteJson
    );
    smallAsteSpriteSheet.parse(() => {
      this.smallAsteSprites = smallAsteSpriteSheet.textures;
    });
    this.asteContainer = new this.PIXI.Container();
    // Explosion sprite
    const explosionSpriteSheet = new this.PIXI.Spritesheet(
      this.app.loader.resources.explosion.texture,
      explosionJson
    );
    explosionSpriteSheet.parse(() => {
      this.explosionSprites = explosionSpriteSheet.textures;
    });
    this.explosionContainer = new this.PIXI.Container();

    this.app.stage.addChild(this.backStage);
    this.app.stage.addChild(this.missileContainer);
    this.app.stage.addChild(this.asteContainer);
    this.app.stage.addChild(this.explosionContainer);
    this.app.stage.addChild(this.ship);

    this.message = new this.PIXI.Text('Score 0');
    this.message.scale.set(0.8, 0.8);
    this.message.x = this.app.renderer.width / 2 - this.message.width / 2;
    this.infoPlay = new this.PIXI.Text('');
    this.infoPlay.scale.set(0.65, 0.65);
    this.infoPlay.x = this.app.renderer.width / 2 - this.infoPlay.width / 2;
    this.message.y = this.infoPlay.height;
    this.app.stage.addChild(this.message);
    this.app.stage.addChild(this.infoPlay);

    this.init();
    this.sprittesLoading = false;
    this.app.ticker.add((deltaTime) => this.gameLoop(deltaTime));
  }

  gameLoop() {
    if (!this.sprittesLoading) {
      const deltaTime = this.app.ticker.elapsedMS;
      this.updateShip(deltaTime);
      this.updateBullets(deltaTime);
      this.updateAste(deltaTime);
      this.updateExplosion(deltaTime);
    }
  }

  moveShipToPositionPP(position) {
    this.usingKeyboard = false;
    this.keyboardShipSpeedx = 0;
    this.keyboardShipSpeedy = 0;
    if (
      position.x > 0 &&
      position.x < this.app.renderer.width &&
      position.y > 0 &&
      position.y < this.app.renderer.height
    ) {
      // Verify is mouse is on screen
      this.moveShipToPosition.x = position.x;
      this.moveShipToPosition.y = position.y;
    }
  }

  velocityShipToPositionPP(y, x) {
    const orientation =
      (window.screen.orientation || {}).type ||
      window.screen.mozOrientation ||
      window.screen.msOrientation;
    if (orientation === 'landscape-primary') {
      this.moveShipToPosition.vx = y;
      this.moveShipToPosition.vy = -x;
    } else if (orientation === 'landscape-secondary') {
      this.moveShipToPosition.vx = -y;
      this.moveShipToPosition.vy = x;
    } else if (orientation === 'portrait-secondary' || orientation === 'portrait-primary') {
      this.moveShipToPosition.vx = x;
      this.moveShipToPosition.vy = y;
    } else if (orientation === undefined) {
      this.setSupportOrientation(false);
    }
  }

  setSupportOrientation(value) {
    if (value) {
      this.infoPlay.text = 'Play with device orientation';
      this.infoPlay.x = this.app.renderer.width / 2 - this.infoPlay.width / 2;
    } else if (!value) {
      this.infoPlay.text = 'Play with mouse or arrow keys';
      this.infoPlay.x = this.app.renderer.width / 2 - this.infoPlay.width / 2;
    }
    this.supportOrientation = value;
  }

  downKey(event) {
    if (event.key === 'ArrowUp') {
      event.preventDefault();
      this.keyboardShipForward = true;
      this.usingKeyboard = true;
    } else if (event.key === 'ArrowLeft') {
      event.preventDefault();
      this.keyboardRotateDiretion = -1;
      this.usingKeyboard = true;
    } else if (event.key === 'ArrowRight') {
      event.preventDefault();
      this.keyboardRotateDiretion = 1;
      this.usingKeyboard = true;
    } else if (event.key === 'ArrowDown') {
      event.preventDefault();
      this.usingKeyboard = true;
    }
  }

  upKey(event) {
    if (event.key === 'ArrowUp') {
      event.preventDefault();
      this.keyboardShipForward = false;
      this.usingKeyboard = true;
    } else if (event.key === 'ArrowLeft') {
      event.preventDefault();
      this.keyboardRotateDiretion = 0;
      this.usingKeyboard = true;
    } else if (event.key === 'ArrowRight') {
      event.preventDefault();
      this.keyboardRotateDiretion = 0;
      this.usingKeyboard = true;
    } else if (event.key === 'ArrowDown') {
      event.preventDefault();
      this.usingKeyboard = true;
    }
  }

  init() {
    this.ship.x = this.app.renderer.width / 2;
    this.ship.y = this.app.renderer.height / 2;
    this.shipAnimationTimerCounter = 0;
    this.shipAnimationTimer = 500;
    this.shipAnimationSprite = 0;
    this.shipSpeed = 1.2;
    this.shipAtrision = 1;
    this.shipInvunerable = false;
    this.shipInvunerableTimerCounter = 0;
    this.shipInvunerableTimer = 100;
    this.shipInvunerableCounter = 0;

    this.bulletFiringTimerCounter = 0;
    this.bulletFiringTimer = 1000;
    this.bulletSpriteFiringTimerCounter = 0;
    this.bulletSpriteFiringTimer = 100;
    this.bullets = [];
    this.bulletVelocity = 10;

    this.asteSpawnerTimerCounter = 0;
    this.asteSpawnerTimer = 1000;
    this.asteArray = [];

    this.explosionArray = [];
    this.explosionTimerCounter = 0;
    this.explosionTimer = 40;

    this.score = 0;

    this.screenDivisionDistance =
      Math.sqrt(this.app.renderer.width ** 2 + this.app.renderer.height ** 2) / 4;
    this.moveShipToPosition = {
      x: this.app.renderer.width / 2,
      y: this.app.renderer.height / 2,
      vx: 0,
      vy: 0,
    };
    const move = this.moveShipToPositionPP.bind(this);
    function touch(e) {
      move(e.data.getLocalPosition(this.parent));
    }
    this.backStage.interactive = true;
    this.backStage.on('pointermove', touch);
    this.backStage.on('pointerdown', touch);
    const velocity = this.velocityShipToPositionPP.bind(this);
    this.setSupportOrientation(false);
    const orientation = this.setSupportOrientation.bind(this);
    function handleOrientation(event) {
      let x = event.beta;
      const y = event.gamma;
      if (x != null && y != null) {
        if (x > 90) x = 90;
        if (x < -90) x = -90;
        orientation(true);
        velocity(x / 90, y / 90);
      } else {
        orientation(false);
      }
    }
    window.addEventListener('deviceorientation', handleOrientation, true);
    const down = this.downKey.bind(this);
    const up = this.upKey.bind(this);
    window.addEventListener(
      'keydown',
      (e) => {
        down(e);
      },
      false
    );
    window.addEventListener(
      'keyup',
      (e) => {
        up(e);
      },
      false
    );
  }

  updateShip(deltaTime) {
    this.shipAnimationTimerCounter += deltaTime;
    if (this.shipInvunerable) {
      this.shipInvunerableTimerCounter += deltaTime;
      if (this.shipInvunerableTimerCounter > this.shipAnimationTimer) {
        if (this.ship.alpha === 0.25) this.ship.alpha = 0.5;
        else this.ship.alpha = 0.25;
        this.shipInvunerableTimerCounter = 0;
        this.shipInvunerableCounter += 1;
        if (this.shipInvunerableCounter > 10) {
          this.ship.alpha = 1;
          this.shipInvunerableCounter = 0;
          this.shipInvunerable = false;
        }
      }
    }
    const distance = Math.sqrt(
      (this.moveShipToPosition.x - this.ship.x) ** 2 +
        (this.moveShipToPosition.y - this.ship.y) ** 2
    );
    // Change sprites
    let shipSpriteSeletected = 0;
    if (this.supportOrientation) {
      if (this.moveShipToPosition.vx > 1 || this.moveShipToPosition.vy > 1) {
        shipSpriteSeletected = 2;
      } else if (this.moveShipToPosition.vx > 1.5 || this.moveShipToPosition.vy > 1.5) {
        shipSpriteSeletected = 3;
      } else if (this.moveShipToPosition.vx > 2 || this.moveShipToPosition.vy > 2) {
        shipSpriteSeletected = 4;
      }
    } else if (this.usingKeyboard) {
      if (
        this.keyboardShipSpeedx > this.keyboardShipSpeedMax / 2 ||
        this.keyboardShipSpeedy > this.keyboardShipSpeedMax / 2
      ) {
        shipSpriteSeletected = 2;
      } else if (
        this.keyboardShipSpeedx > (this.keyboardShipSpeedMax * 3) / 5 ||
        this.keyboardShipSpeedy > (this.keyboardShipSpeedMax * 3) / 5
      ) {
        shipSpriteSeletected = 3;
      } else if (
        this.keyboardShipSpeedx > (this.keyboardShipSpeedMax * 4) / 5 ||
        this.keyboardShipSpeedy > (this.keyboardShipSpeedMax * 4) / 5
      ) {
        shipSpriteSeletected = 4;
      }
    } else {
      shipSpriteSeletected = Math.floor(distance / this.screenDivisionDistance);
    }
    if (shipSpriteSeletected === 0) {
      if (this.shipAnimationTimerCounter > this.shipAnimationTimer) {
        this.shipAnimationTimerCounter = 0;
        this.ship.texture = this.shipSprites[`Ship${this.shipAnimationSprite}.png`];
        this.ship.texture.baseTexture.scaleMode = this.PIXI.SCALE_MODES.NEAREST;
        this.shipAnimationSprite += 1;
        if (this.shipAnimationSprite > 1) this.shipAnimationSprite = 0;
      }
    } else {
      this.ship.texture = this.shipSprites[`Ship${shipSpriteSeletected + 1}.png`];
      this.ship.texture.baseTexture.scaleMode = this.PIXI.SCALE_MODES.NEAREST;
    }
    // Move ship
    if (this.supportOrientation) {
      this.ship.x += this.moveShipToPosition.vx * deltaTime;
      this.ship.y += this.moveShipToPosition.vy * deltaTime;
    } else if (this.usingKeyboard) {
      // If use using keyboard
      if (!this.keyboardShipForward) {
        // If the ship isn't moving forward slow down
        this.keyboardShipSpeedx -=
          this.keyboardShipSpeedx * (this.shipAtrision / 2) * (deltaTime / 1000);
        this.keyboardShipSpeedy -=
          this.keyboardShipSpeedy * (this.shipAtrision / 2) * (deltaTime / 1000);
      } else {
        // If the ship is moving forward give speed
        if (this.keyboardShipSpeedx < 0 && -this.keyboardShipSpeedx > this.keyboardShipSpeedMax)
          this.keyboardShipSpeedx = -this.keyboardShipSpeedMax;
        else if (this.keyboardShipSpeedx > 0 && this.keyboardShipSpeedx > this.keyboardShipSpeedMax)
          this.keyboardShipSpeedx = this.keyboardShipSpeedMax;
        else
          this.keyboardShipSpeedx +=
            Math.cos(this.ship.rotation - Math.PI / 2) * this.shipAtrision * 2 * (deltaTime / 1000);
        if (this.keyboardShipSpeedy < 0 && -this.keyboardShipSpeedy > this.keyboardShipSpeedMax)
          this.keyboardShipSpeedy = -this.keyboardShipSpeedMax;
        else if (this.keyboardShipSpeedy > 0 && this.keyboardShipSpeedy > this.keyboardShipSpeedMax)
          this.keyboardShipSpeedy = this.keyboardShipSpeedMax;
        else
          this.keyboardShipSpeedy +=
            Math.sin(this.ship.rotation - Math.PI / 2) * this.shipAtrision * 2 * (deltaTime / 1000);
      }
      if (this.keyboardRotateDiretion === -1) {
        this.ship.rotation -= Math.PI / 100;
      } else if (this.keyboardRotateDiretion === 1) {
        this.ship.rotation += Math.PI / 100;
      }
      this.ship.x += this.keyboardShipSpeedx;
      this.ship.y += this.keyboardShipSpeedy;
    } else {
      this.ship.x +=
        (this.moveShipToPosition.x - this.ship.x) * this.shipSpeed * (deltaTime / 1000);
      this.ship.y +=
        (this.moveShipToPosition.y - this.ship.y) * this.shipSpeed * (deltaTime / 1000);
    }
    // Check if ship out of screen
    if (this.ship.x > this.app.renderer.width + this.ship.width) this.ship.x = -this.ship.width;
    if (this.ship.x + this.ship.width < 0) this.ship.x = this.app.renderer.width + this.ship.width;
    if (this.ship.y > this.app.renderer.height + this.ship.height) this.ship.y = -this.ship.height;
    if (this.ship.y + this.ship.height < 0)
      this.ship.y = this.app.renderer.height + this.ship.height;
    // Check if position to go is out of screen
    if (this.moveShipToPosition.x > this.app.renderer.width)
      this.moveShipToPosition.x = this.app.renderer.width;
    if (this.moveShipToPosition.x < 0) this.moveShipToPosition.x = 0;
    if (this.moveShipToPosition.y > this.app.renderer.height)
      this.moveShipToPosition.y = this.app.renderer.height;
    if (this.moveShipToPosition.y < 0) this.moveShipToPosition.y = 0;
    // Rotate the ship
    if (this.supportOrientation) {
      const angle = Math.atan2(this.moveShipToPosition.vy, this.moveShipToPosition.vx);
      this.ship.rotation = angle + Math.PI / 2;
    } else if (!this.usingKeyboard) {
      const angle = Math.atan2(
        this.moveShipToPosition.y - this.ship.y,
        this.moveShipToPosition.x - this.ship.x
      );
      this.ship.rotation = angle + Math.PI / 2;
    }
  }

  updateBullets(deltaTime) {
    this.bulletFiringTimerCounter += deltaTime;
    this.bulletSpriteFiringTimerCounter += deltaTime;
    // Spawn Bullet
    if (this.bulletFiringTimerCounter > this.bulletFiringTimer) {
      this.bulletFiringTimerCounter = 0;
      const newBullet = new this.PIXI.Sprite(this.missileSprites[`Missile${0}.png`]);
      newBullet.anchor.set(0.5, 0.5);
      newBullet.texture.baseTexture.scaleMode = this.PIXI.SCALE_MODES.NEAREST;
      newBullet.scale.x = 0.75;
      newBullet.scale.y = 0.75;

      newBullet.x = this.ship.x;
      newBullet.y = this.ship.y;
      newBullet.rotation = this.ship.rotation;
      this.bullets.push({
        object: newBullet,
        currentSprite: 0,
        vx: Math.cos(this.ship.rotation - Math.PI / 2) * this.bulletVelocity,
        vy: Math.sin(this.ship.rotation - Math.PI / 2) * this.bulletVelocity,
      });
      this.missileContainer.addChild(newBullet);
    }
    // Iterate over bullets
    this.bullets = this.bullets.filter((bullet) => {
      if (
        bullet.object.x > this.app.renderer.width ||
        bullet.object.x < 0 ||
        bullet.object.y > this.app.renderer.height ||
        bullet.object.y < 0
      ) {
        this.missileContainer.removeChild(bullet.object);
        return false;
      }
      if (this.bulletSpriteFiringTimerCounter > this.bulletSpriteFiringTimer) {
        bullet.currentSprite += 1;
        if (bullet.currentSprite > 3) bullet.currentSprite = 0;
        bullet.object.texture = this.missileSprites[`Missile${bullet.currentSprite}.png`];
        bullet.object.texture.baseTexture.scaleMode = this.PIXI.SCALE_MODES.NEAREST;
      }
      bullet.object.x += bullet.vx;
      bullet.object.y += bullet.vy;
      return true;
    });
    if (this.bulletSpriteFiringTimerCounter > this.bulletSpriteFiringTimer) {
      this.bulletSpriteFiringTimerCounter = 0;
    }
  }

  updateAste(deltaTime) {
    this.asteSpawnerTimerCounter += deltaTime;
    if (this.asteSpawnerTimerCounter > this.asteSpawnerTimer) {
      this.asteSpawnerTimerCounter = 0;
      const randomAste = Math.floor(Math.random() * 3);
      const newAste = new this.PIXI.Sprite(this.bigAsteSprites[`BigAsteroide${randomAste}.png`]);
      newAste.anchor.set(0.5, 0.5);
      newAste.texture.baseTexture.scaleMode = this.PIXI.SCALE_MODES.NEAREST;
      const spawnPoint = Math.floor(Math.random() * 8);
      let velocityX = 0;
      let velocityY = 0;
      // Random Spawn point
      if (spawnPoint === 0) {
        // Top left
        newAste.x = Math.floor(Math.random() * (this.app.renderer.width / 2 - 0)) + 0;
        newAste.y = -200;
        velocityX = Math.random() * (0.4 - 0.1) + 0.1;
        velocityY = Math.random() * (0.4 - 0.1) + 0.1;
      } else if (spawnPoint === 1) {
        // Top right
        newAste.x =
          Math.floor(Math.random() * (this.app.renderer.width - this.app.renderer.width / 2)) +
          this.app.renderer.width / 2;
        newAste.y = -200;
        velocityX = -(Math.random() * (0.4 - 0.1) + 0.1);
        velocityY = Math.random() * (0.4 - 0.1) + 0.1;
      } else if (spawnPoint === 2) {
        // Right top
        newAste.x = this.app.renderer.width;
        newAste.y = Math.floor(Math.random() * (this.app.renderer.height / 2 - 0)) + 0;
        velocityX = -(Math.random() * (0.4 - 0.1) + 0.1);
        velocityY = Math.random() * (0.4 - 0.1) + 0.1;
      } else if (spawnPoint === 3) {
        // Right bottom
        newAste.x = this.app.renderer.width;
        newAste.y =
          Math.floor(Math.random() * (this.app.renderer.height - this.app.renderer.height / 2)) +
          this.app.renderer.height / 2;
        velocityX = -(Math.random() * (0.4 - 0.1) + 0.1);
        velocityY = -(Math.random() * (0.4 - 0.1) + 0.1);
      } else if (spawnPoint === 4) {
        // Bottom right
        newAste.x =
          Math.floor(Math.random() * (this.app.renderer.width - this.app.renderer.width / 2)) +
          this.app.renderer.width / 2;
        newAste.y = this.app.renderer.height;
        velocityX = -(Math.random() * (0.4 - 0.1) + 0.1);
        velocityY = -(Math.random() * (0.4 - 0.1) + 0.1);
      } else if (spawnPoint === 5) {
        // Bottom left
        newAste.x = Math.floor(Math.random() * (this.app.renderer.width / 2 - 0)) + 0;
        newAste.y = this.app.renderer.height;
        velocityX = Math.random() * (0.4 - 0.1) + 0.1;
        velocityY = -(Math.random() * (0.4 - 0.1) + 0.1);
      } else if (spawnPoint === 6) {
        // Left bottom
        newAste.x = -200;
        newAste.y =
          Math.floor(Math.random() * (this.app.renderer.height - this.app.renderer.height / 2)) +
          this.app.renderer.height / 2;
        velocityX = Math.random() * (0.4 - 0.1) + 0.1;
        velocityY = -(Math.random() * (0.4 - 0.1) + 0.1);
      } else if (spawnPoint === 7) {
        // Left top
        newAste.x = -200;
        newAste.y = Math.floor(Math.random() * (this.app.renderer.height / 2 - 0)) + 0;
        velocityX = Math.random() * (0.4 - 0.1) + 0.1;
        velocityY = Math.random() * (0.4 - 0.1) + 0.1;
      }
      newAste.scale.x = Math.random() * (1.2 - 1) + 1;
      newAste.scale.y = Math.random() * (1.2 - 1) + 1;

      this.asteArray.push({
        rotationSpeed: Math.random() * (Math.PI / 100),
        direction: Math.floor(Math.random() * 2),
        object: newAste,
        vx: velocityX,
        vy: velocityY,
        big: true,
      });

      this.asteContainer.addChild(newAste);
    }
    const newArray = [];
    for (let i = 0; i < this.asteArray.length; i += 1) {
      this.asteArray[i].object.x += (this.asteArray[i].vx / 2) * deltaTime;
      this.asteArray[i].object.y += (this.asteArray[i].vy / 2) * deltaTime;
      if (this.asteArray[i].direction === 0)
        this.asteArray[i].object.rotation += this.asteArray[i].rotationSpeed;
      else this.asteArray[i].object.rotation -= (this.asteArray[i].rotationSpeed / 10) * deltaTime;
      if (
        this.asteArray[i].object.x < -200 ||
        this.asteArray[i].object.y < -200 ||
        this.asteArray[i].object.x > this.app.renderer.width + 200 ||
        this.asteArray[i].object.y > this.app.renderer.height + 200
      ) {
        this.asteContainer.removeChild(this.asteArray[i].object);
        continue;
      }
      let hitted = false;
      // If ship hit
      if (!this.shipInvunerable && hitTestRectangle(this.ship, this.asteArray[i].object)) {
        this.shipInvunerable = true;
        this.ship.alpha = 0.25;
        this.ship.x = this.app.renderer.width / 2;
        this.ship.y = this.app.renderer.height / 2;
        hitted = true;
        const newExplosion = new this.PIXI.Sprite(this.explosionSprites['ExplosionSpace.png']);
        newExplosion.texture.baseTexture.scaleMode = this.PIXI.SCALE_MODES.NEAREST;
        newExplosion.anchor.set(0.5, 0.5);
        newExplosion.x = this.asteArray[i].object.x;
        newExplosion.y = this.asteArray[i].object.y;
        newExplosion.rotation = Math.random() * (Math.PI * 2);
        newExplosion.scale.x = 2;
        newExplosion.scale.y = 2;
        this.explosionArray.push({
          currentSprite: 0,
          object: newExplosion,
        });
        this.explosionContainer.addChild(newExplosion);
        this.score -= 50;
        if (this.score < 0) this.score = 0;
        this.message.text = `Score ${this.score}`;
        this.message.x = this.app.renderer.width / 2 - this.message.width / 2;
      }
      const newButtlerArray = [];
      for (let j = 0; j < this.bullets.length; j += 1) {
        if (hitTestRectangle(this.asteArray[i].object, this.bullets[j].object)) {
          hitted = true;
          this.missileContainer.removeChild(this.bullets[j].object);
          if (this.asteArray[i].big) {
            this.score += 10;
            // Spawn smaller asteroids
            const nSmallAste = Math.floor(Math.random() * (4 - 1)) + 1;
            for (let k = 0; k < nSmallAste; k += 1) {
              const randomSmallAste = Math.floor(Math.random() * 3);
              const newSmallAste = new this.PIXI.Sprite(
                this.smallAsteSprites[`SmallAsteroid${randomSmallAste}.png`]
              );
              newSmallAste.texture.baseTexture.scaleMode = this.PIXI.SCALE_MODES.NEAREST;
              newSmallAste.anchor.set(0.5, 0.5);
              newSmallAste.x = this.asteArray[i].object.x;
              newSmallAste.y = this.asteArray[i].object.y;
              newSmallAste.scale.x = Math.random() * (1.2 - 1) + 1;
              newSmallAste.scale.y = Math.random() * (1.2 - 1) + 1;
              const randomDirectionToGoAste = Math.floor(Math.random() * 4);
              let velocitySmallX = 0;
              let velocitySmallY = 0;
              if (randomDirectionToGoAste === 0) {
                velocitySmallX = -(Math.random() * (0.2 - 0.1) + 0.1);
                velocitySmallY = -(Math.random() * (0.2 - 0.1) + 0.1);
              } else if (randomDirectionToGoAste === 1) {
                velocitySmallX = Math.random() * (0.2 - 0.1) + 0.1;
                velocitySmallY = -(Math.random() * (0.2 - 0.1) + 0.1);
              } else if (randomDirectionToGoAste === 2) {
                velocitySmallX = Math.random() * (0.2 - 0.1) + 0.1;
                velocitySmallY = Math.random() * (0.2 - 0.1) + 0.1;
              } else if (randomDirectionToGoAste === 3) {
                velocitySmallX = -(Math.random() * (0.2 - 0.1) + 0.1);
                velocitySmallY = Math.random() * (0.2 - 0.1) + 0.1;
              }
              newArray.push({
                rotationSpeed: Math.random() * (Math.PI / 100),
                direction: Math.floor(Math.random() * 2),
                object: newSmallAste,
                vx: velocitySmallX,
                vy: velocitySmallY,
                big: false,
              });
              this.asteContainer.addChild(newSmallAste);
            }
          } else this.score += 5;
          const newExplosion = new this.PIXI.Sprite(this.explosionSprites['ExplosionSpace.png']);
          newExplosion.texture.baseTexture.scaleMode = this.PIXI.SCALE_MODES.NEAREST;
          newExplosion.anchor.set(0.5, 0.5);
          newExplosion.x = this.asteArray[i].object.x;
          newExplosion.y = this.asteArray[i].object.y;
          newExplosion.rotation = Math.random() * (Math.PI * 2);
          newExplosion.scale.x = 2;
          newExplosion.scale.y = 2;
          this.explosionArray.push({
            currentSprite: 0,
            object: newExplosion,
          });
          this.explosionContainer.addChild(newExplosion);
          if (this.score > 99999) this.score = 99999;
          this.message.text = `Score ${this.score}`;
          this.message.x = this.app.renderer.width / 2 - this.message.width / 2;
          continue;
        }
        newButtlerArray.push(this.bullets[j]);
      }
      this.bullets = newButtlerArray;
      if (!hitted) newArray.push(this.asteArray[i]);
      else {
        this.asteContainer.removeChild(this.asteArray[i].object);
      }
    }
    this.asteArray = newArray;
  }

  updateExplosion(deltaTime) {
    this.explosionTimerCounter += deltaTime;
    if (this.explosionTimerCounter > this.explosionTimer) {
      this.explosionTimerCounter = 0;
      this.explosionArray = this.explosionArray.filter((ex) => {
        ex.currentSprite += 1;
        if (ex.currentSprite > 8) {
          this.explosionContainer.removeChild(ex.object);
          return false;
        }
        ex.object.texture = this.explosionSprites[`ExplosionSpace${ex.currentSprite}.png`];
        ex.object.texture.baseTexture.scaleMode = this.PIXI.SCALE_MODES.NEAREST;
        return true;
      });
    }
  }

  screenResize() {
    this.backStage.clear();
    this.backStage.beginFill(0xffffff, 1);
    this.backStage.drawRect(0, 0, this.app.renderer.width, this.app.renderer.height);
    this.backStage.endFill();
    this.screenDivisionDistance =
      Math.sqrt(this.app.renderer.width ** 2 + this.app.renderer.height ** 2) / 4;
    this.message.x = this.app.renderer.width / 2 - this.message.width / 2;
    this.infoPlay.x = this.app.renderer.width / 2 - this.infoPlay.width / 2;
  }

  get PixiApp() {
    return this.app;
  }
}
export default MainGame;
